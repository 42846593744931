import React from 'react';
import cn from 'classnames';
import { Icon, IconType } from '@dealroadshow/uikit';
import URL from '@/finsight/Router/urlConstants';
import { Link } from '@/ui/shared/components/Next/Link';

import styles from './styles.scss';

const UpgradePlanButton = ({ className = '', dataTest = '' }) => (
  <Link
    data-test={ dataTest || 'upgradePlanButton' }
    className={ cn(styles.button, className) }
    to={ URL.PRICING }
  >
    Upgrade Plan
    <Icon
      type={ IconType.unlock }
      className={ styles.icon }
    />
  </Link>
  );

export default UpgradePlanButton;
