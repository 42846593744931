import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import FinsightModal from '@/finsight/ui/common/components/FinsightModal';
import ModalFormRepository from '@/finsight/infrastructure/repository/ModalFormRepository';
import { NotificationManager } from '@/ui/shared/components/Notification';
import JoinWaitlistForm from './JoinWaitlistForm';
import { IJoinWaitlistFormValues } from './interfaces';

const JoinWaitlistModal = ({ closeModal, isOpen }: { closeModal: () => void, isOpen: boolean }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitSucceed, setIsSubmitSucceed] = useState(false);

  const closeModalHandler = () => {
    closeModal();
    setIsSubmitSucceed(false);
  };

  const { container } = useDIContext();
  const modalFormRepository = container.get<ModalFormRepository>(ModalFormRepository);

  const onSubmit = async (values: IJoinWaitlistFormValues) => {
    setIsFetching(true);

    try {
      const token = await values.recaptcha.executeAsync();
      const response = await modalFormRepository.joinOpenmarketWaitlist({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        comment: values.comment ?? '',
        token,
      });

      if (response.result === 'error') {
        throw new Error(response.reason);
      }

      setIsSubmitSucceed(true);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <FinsightModal
      hideOnOverlayClicked
      modalTitle={ (
        <>
          Sign up for the OpenMarket <br /> Waitlist
        </>
      ) }
      isFetching={ isFetching }
      isSuccessOverlay
      isSubmitSucceed={ isSubmitSucceed }
      isVisible={ isOpen }
      dataTest="requestServiceModal"
      onCloseClicked={ closeModalHandler }
      successText="You have joined OpenMarket Waiting list"
    >
      <JoinWaitlistForm onSubmit={ onSubmit } />
    </FinsightModal>
  );
};

export default JoinWaitlistModal;
