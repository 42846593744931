import { useState } from 'react';
import cn from 'classnames';
import NoAccessOverlay from '@/finsight/ui/common/components/NoAccessOverlay';
import MissingSomethingModalButton from '@/finsight/ui/common/components/MissingSomethingModalButton';
import { PageContentLayout } from '@/finsight/ui/common/components/ContentLayouts';
import PageHeaderWrapper from '@/finsight/ui/common/components/PageHeaderWrapper';
import image from '@/finsight/ui/assets/noAccess/openMarketNoAccess.webp';
import headerWrapperStyles from '@/finsight/ui/common/components/PageHeaderWrapper/pageHeaderWrapper.scss';
import JoinWaitlistModal from '@/openMarket/ui/common/Finsight/JoinWaitlistModal';

import {
  OPEN_MARKET_PAGE_TITLE,
  openMarketNoAccessDescription,
  openMarketNoAccessFeatures,
  openMarketImageCaption,
} from './constants';
import { Button, ButtonVariantType } from '@dealroadshow/uikit';

import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import styles from './comingSoon.scss';

const ComingSoon = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <PageContentLayout>
      <PageHeaderWrapper title="OpenMarket (Closed Beta)" className={ headerWrapperStyles.pageHeaderNoWrap } />
      <NoAccessOverlay
        title={ OPEN_MARKET_PAGE_TITLE }
        description={ openMarketNoAccessDescription }
        features={ openMarketNoAccessFeatures }
        imageCaption={ openMarketImageCaption }
        image={ image }
        customButton={ () => (
          <Button
            dataTest="joinWaitingListButton"
            variant={ ButtonVariantType.action }
            onClick={ openModal }
            className={ styles.actionButton }
          >
            Join Waiting List
          </Button>
        ) }
      />
      <MissingSomethingModalButton className={ cn(spacesStyles.ptxl, spacesStyles.pbxl) } />
      <JoinWaitlistModal closeModal={ () => setIsModalOpen(false) } isOpen={ isModalOpen } />
    </PageContentLayout>
  );
};

export default ComingSoon;
