import { validateRecaptchaVerified } from '@/ui/shared/validators/validateRecaptchaVerified';
import { groupValidators } from '@/ui/shared/validators/helpers';
import { validateEmail } from '@/ui/shared/validators/user/validateEmail';
import { validateName } from '@/ui/shared/validators/user/validateName';
import { validateRequiredField } from '@/ui/shared/validators/validateRequiredField';
import { validateMaxLength } from '@/ui/shared/validators/validateMaxLength';
import { IJoinWaitlistFormValues } from './interfaces';

const firstName = { fieldName: 'firstName', fieldCaption: 'First Name' };
const lastName = { fieldName: 'lastName', fieldCaption: 'Last Name' };
const email = { fieldName: 'email', fieldCaption: 'Corporate Email' };
const comment = { fieldName: 'comment', fieldCaption: 'Comment' };

export default (values: IJoinWaitlistFormValues) => groupValidators(
    validateRequiredField({ ...firstName, value: values.firstName }),
    validateName({ ...firstName, value: values.firstName }),
    validateRequiredField({ ...lastName, value: values.lastName }),
    validateName({ ...lastName, value: values.lastName }),
    validateRequiredField({ ...email, value: values.email }),
    validateEmail({ ...email, value: values.email }),
    validateMaxLength({ ...comment, value: values.comment, length: 1000 }),
    validateRecaptchaVerified({ fieldName: 'recaptcha', value: values.recaptcha }),
  );
